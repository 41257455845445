import { useState, useEffect } from 'react'
import Head from 'next/head';
import Body from '../components/Body';
import NavBar from '../components/NavBar';
import Home from '../components/Home';

export default function Index() {
	const [isVisible, setIsVisible] = useState(false);
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};
	useEffect(() => {
		// Button is displayed after scrolling for 500 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 500) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);
	return (
		<>
			<Head>
				<title>Home - Bakthavachalam Babu</title>
				<html lang="en">

					<meta name="viewport" content="width=device-width, initial-scale=1" />
					<meta charSet="utf-8" />
					<meta name="description" content='Algae database - Marine and Freshwater Algae images with description and data'></meta>
					<meta property="og:title" content='Bakthavachalam Babu Portfolio and Algae Database' key="ogtitle" />
					<meta property="og:description" content='Algae database - Marine and Freshwater Algae images with description and data' key="ogdesc" />
					<meta name="robots" content="index, follow" />
					<link rel='preconnect' href='https://fonts.gstatic.com' />
					<meta name="google" content="notranslate" />
					<link
						href='https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css'
						rel='stylesheet'
					/>
					<link
						href='https://fonts.googleapis.com/css2?family=Recursive:wght@700&display=swap'
						rel='stylesheet'
					/>
					<link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
					<link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
					<link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
					<link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
					<meta name="msapplication-TileColor" content="#da532c" />
					<meta name="theme-color" content="#ffffff" />
				</html>
			</Head>
			<body className='bg-gallery bg-center bg-cover bg-no-repeat bg-fixed'>
				{isVisible && (
					<button aria-label="scroll" onClick={scrollToTop} className="focus:outline-none fixed right-0 bottom-0 text-center flex rounded-full w-16 m-10 h-16 bg-primary text-yellow-500 items-center justify-center z-30">
						<div className='inline-flex m-10'><svg xmlns="http://www.w3.org/2000/svg" fill="#FFBF00" width="24" height="24" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z" /></svg></div>
					</button>
				)}
				<div>
					<NavBar />
					<Home />
				</div>
				<div className='p-5'>
					<Body />
				</div>
			</body>
		</>
	)
}
